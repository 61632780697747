<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="bag-check-single">
      <b-container fluid="100%">
        <b-row class="serach-wrap">
          <b-col class="col-sm-10 col-md-6 col-lg-6 col-xl-4">
            <div class="serach-wrap-inner">
              <div class="search-input-box">
                <input type="text" v-model="keywords" class="search-input-info" placeholder="请输入关键词，或搜索产品型号" @keyup.enter="search">
                <div class="search-split-box margin-left-auto">
                  <img src="@/../public/icon/product-distingush@2x.png" alt="" class="search-icon">
                </div>
                <div class="search-split-box">
                  <img src="@/../public/icon/search-split@2x.png" alt="" class="search-split">
                </div>
                <div class="search-split-box margin-right-22" @click="search">
                  <img src="@/../public/icon/search-icon@2x.png" alt="" class="search-icon">
                </div>
              </div>
              <div class="key-words-box">
                <span class="key-words-title">{{ $t("homeSearchPlaceholder") }}:</span>
                <span class="key-words" v-for="(item, index) in hotKeywords" :key="index" @click="hotSearch(item)">{{item.key}}</span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="classify-wrap">
          <b-col class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <div class="classify-wrap-inner">
              <div class="classify-items-wrap">
                <div class="classify-title">一级分类</div>
                <div class="classify-items-inner">
                  <span :class="cateOneId == item.LABEL_ID ? 'classify-item selected' : 'classify-item'" v-for="(item, index) in cateOne" :key="index" @click="changeCateOne(item)">{{item.LABEL_NAME}}</span>
                </div>
                
              </div>
              <div v-if="cateOneId" class="classify-items-wrap">
                <div class="classify-title">二级分类</div>
                <div class="classify-items-inner">
                  <span :class="cateTwoId == item.LABEL_ID ? 'classify-item selected' : 'classify-item'" v-for="(item, index) in cateTwo" :key="index" @click="changeCateTwo(item)">{{item.LABEL_NAME}}</span>
                </div>
                
              </div>
              <div v-if="cateOneId && cateTwoId" class="classify-items-wrap">
                <div class="classify-title">三级分类</div>
                <div class="classify-items-inner">
                  <span :class="cateThreeId == item.LABEL_ID ? 'classify-item selected' : 'classify-item'" v-for="(item, index) in cateThree" :key="index" @click="changeCateThree(item)">{{item.LABEL_NAME}}</span>
                </div>
                
              </div>
              <div class="classify-items-wrap">
                <div class="classify-title">风格</div>
                <div class="classify-items-inner">
                  <span :class="styleId == item.LABEL_ID ? 'classify-item selected' : 'classify-item'" v-for="(item, index) in styleList" :key="index" @click="changeStyle(item)">{{item.LABEL_NAME}}</span>
                </div>
                
              </div>
              <div class="classify-items-wrap">
                <div class="classify-title">颜色</div>
                <div class="classify-items-inner">
                  <span class="color-item" :style="`background-color: ${item.COLOR};`" v-for="(item, index) in colors" :key="index" @click="changeColor(item)">
                    <i :class="color == item.COLOR ? 'el-icon-check active' : 'el-icon-check'"></i>
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="sort-wrap">
          <b-col class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <div class="sort-wrap-inner">
              <div class="sort-title">
                <i class="el-icon-sort"></i>排序
              </div>
              <div class="sort-items">
                <span :class="sort == item.sort ? 'sort-item selected' : 'sort-item'" v-for="(item, index) in sortArr" :key="index" @click="changeSort(item)">{{item.name}}</span>
                <span :class="[sort == 3 || sort == 4 ?'sort-item-price price-selected':'sort-item-price']" @click="changePriceSort">
                  <span class="text-txt">价格</span>
                  <div class="arrow">
                    <span :class="[priceSortMark ? 'arrow-top-state':'arrow-top']"></span>
                    <span :class="[priceSortMark ? 'arrow-bot-state':'arrow-bot']"></span>
                  </div>
                </span>
                <span class="sort-item-model">
                  <el-checkbox v-model="checked" @change="changeModel">模型</el-checkbox>
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="product-items-wrap">
          <b-col class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <div class="product-wrap-inner">
              <waterfall :col="colCount" :data="goodsItems">
                <template>
                  <div class="product-item" v-for="(item, index) in goodsItems" :key="index">
                    <div class="pic" @click="toProductDetail(item)">
                      <img :src="item.file_path" alt="">
                    </div>
                    <div class="detail">
                      <div class="name">{{item.goods_name}}</div>
                      <div class="price">¥{{item.sale_price}}</div>
                      <div class="sales-volume">
                        已售<span>{{item.sale_count}}</span>件
                      </div>
                    </div>
                  </div>
                </template>
              </waterfall>
            </div>
          </b-col>
          <b-col class="col-sm-12 col-md-12 col-lg-12 col-xl-12 changePage">
            <el-pagination
              v-if="totalPage > 0"
              :page-count="totalPage"
              @current-change="changePage"
              background
              layout="prev, pager, next"
              prev-text="上一页"
              next-text="下一页"
            ></el-pagination>
          </b-col>
        </b-row>
      </b-container>
      
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import right from "@/components/Right";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      right,
      footerNav
    },
    data (){
      return {
        checked: false,
        priceSortMark: false,
        keywords: "",
        hotKeywords: [
          {
            key: "现代客厅"
          },
          {
            key: "极简"
          },
          {
            key: "美学"
          }
        ],
        sort: "",
        sortArr: [
          {
            sort: "",
            name: "综合"
          },
          {
            sort: "1",
            name: "热门"
          },
          {
            sort: "2",
            name: "新品"
          },
        ],
        colCount: 5,
        goodsItems: [],
        totalPage: 0,
        currentPage: 1,
        classifyList: [],
        cateOne: [],
        cateTwo: [],
        cateThree: [],
        cateOneId: "",
        cateTwoId: "",
        cateThreeId: "",
        styleList: [],
        styleId: "",
        colors: [],
        color: "",
        model_type: ""
      }
    },
    created() {
      this.keywords = this.$route.query.keyWords;
      this.getBagSingle();
      this.getClassify();
      this.getStyle();
      this.getColor();
    },
    methods: {
      changePriceSort(){
        this.priceSortMark = !this.priceSortMark;
        this.sort = this.priceSortMark ? 3 : 4;
        this.getBagSingle();
      },
      changePage(page){
        this.currentPage = page;
        this.getBagSingle();
      },
      // 拎包单品
      getBagSingle(){
        const params = {
          currentPage: this.currentPage,
          page_size: 30,
          style_type: this.styleId,
          first_label_id: this.cateOneId,
          second_label_id: this.cateTwoId,
          third_label_id: this.cateThreeId,
          sort_type: this.sort,
          keywords: this.keywords,
          // city_id: that.cityId,
          bag_type: 1,
          color: this.color,
          model_type: this.model_type
        }
        this.$api.getGoods(params).then(res => {
          if(res.status == 100){
            this.goodsItems = res.data.goods;
            this.totalPage = res.data.totalPage;
          }
        }).catch(err => console.error(err))
      },
      toProductDetail(obj){
        dump.link({
          type: 1,
          link: "/product/details",
          params: {
            good_id: obj.goods_id
          }
        })
      },
      // 获取分类
      getClassify(){
        this.$api.labelList({}).then(res => {
          if(res.status == 100){
            this.cateOne = [{LABEL_ID: "",LABEL_NAME: "全部"}];
            this.classifyList = res.data;
            res.data.forEach(item => {
              this.cateOne.push({
                LABEL_ID: item.LABEL_ID,
                LABEL_NAME: item.LABEL_NAME
              })
            })
          }
        }).catch(err => console.error(err))
      },
      // 切换一级分类
      changeCateOne(obj){
        this.cateOneId = obj.LABEL_ID;
        if(obj.LABEL_ID){
          this.cateTwo = [{LABEL_ID: "",LABEL_NAME: "全部"}];
          this.classifyList.forEach(item => {
            if(this.cateOneId == item.LABEL_ID){
              item.label2.forEach(ite => {
                this.cateTwo.push({
                  LABEL_ID: ite.LABEL_ID,
                  LABEL_NAME: ite.LABEL_NAME
                })
              })
              
            }
          })
        }else{
          this.cateTwoId = "";
          this.cateThreeId = "";
          this.cateTwo = [];
          this.cateThree = [];
        }
        
        this.getBagSingle();
        
      },
      // 切换二级分类
      changeCateTwo(obj){
        this.cateTwoId = obj.LABEL_ID;
        if(obj.LABEL_ID){
          this.cateThree = [{LABEL_ID: "",LABEL_NAME: "全部"}];
          this.classifyList.forEach(item => {
            item.label2.forEach(ite => {
              if(this.cateTwoId == ite.LABEL_ID){
                ite.label3.forEach(it => {
                  this.cateThree.push({
                    LABEL_ID: it.LABEL_ID,
                    LABEL_NAME: it.LABEL_NAME
                  })
                })
              }
            })
          })
        }else{
          this.cateThreeId = "";
          this.cateThree = [];
        }
        
        this.getBagSingle();
      },
      // 切换三级分类
      changeCateThree(obj){
        this.cateThreeId = obj.LABEL_ID;
        this.getBagSingle();
      },
      // 获取风格 
      getStyle(){
        this.$api.styleList({}).then(res => {
          if(res.status == 100){
            this.styleList = res.data;
            this.styleList.unshift({LABEL_ID: "",LABEL_NAME: "全部"})
          }
        }).catch(err => console.error(err))
      },
      // 切换风格
      changeStyle(obj){
        this.styleId = obj.LABEL_ID;
        this.getBagSingle();
      },
      // 获取颜色列表
      getColor(){
        this.$api.getColors({}).then(res => {
          if(res.status == 200){
            this.colors = res.data;
          }
        }).catch(err => console.error(err))
      },
      // 切换颜色
      changeColor(obj){
        this.color = obj.COLOR;
        this.getBagSingle();
      },
      // 切换排序
      changeSort(obj){
        this.sort = obj.sort;
        this.getBagSingle();
      },
      // 点击搜索按钮搜索
      search(){
        console.log("keywords",this.keywords)
        this.getBagSingle();
      },
      hotSearch(key){
        this.keywords = key.key;
        this.getBagSingle();
      },
      changeModel(val){
        this.model_type = val ? 1 : "";
        this.getBagSingle();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "bagCheckSingle";
</style>